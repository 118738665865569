import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import request from '@/request/apis'
import ElementUI from 'element-ui'
import MinCommon from '@/util/index'
import 'element-ui/lib/theme-chalk/index.css'
import store from './store/index'
import AFTableColumn from 'af-table-column'
import SetPsd from '@/components/setPsd.vue'
import SearchKeys from '@/components/searchKeys'
import ExportLoading from '@/components/exportLoading'
Vue.use(AFTableColumn)

// 权限请求
import jurisdictionReques from '@/request/jurisdictionApi.js'
import FieldManagement from '@/components/FieldManagement'
import Guidepage from '@/components/Guidepage.vue'
Vue.config.productionTip = false
import { VueJsonp } from 'vue-jsonp'
import Print from '@/static/print'
Vue.use(VueJsonp)
Vue.use(MinCommon)
Vue.use(ElementUI)
Vue.component('SetPsd', SetPsd)
Vue.component('FieldManagement', FieldManagement)
Vue.component('ExportLoading', ExportLoading)
Vue.component('Guidepage', Guidepage)
Vue.component('SearchKeys', SearchKeys)
Vue.use(Print)
Vue.prototype.$api = request
Vue.prototype.$jurisdiction = jurisdictionReques
Vue.prototype.$store = store
Vue.prototype.$tableListName = 'cjq'
Vue.prototype.$Url = process.env.NODE_ENV === 'production' ? 'https://www.mai47.com' : 'http://test.buy.mai47.com'
Vue.prototype.b2bstaticUrl = process.env.NODE_ENV === 'production' ? 'https://static.mai47.com' : 'http://test.static.rundamedical.com' //
Vue.prototype.b2bapicUrl = process.env.NODE_ENV === 'production' ? 'https://ivdapi.mai47.com/api' : 'http://local.api.saas.mai47.com/api' //

// if (process.env.NODE_ENV === 'development') {
//   Vue.prototype.baseURL = 'http://test.saas.api.mai47.com/api'
// }
// if (process.env.NODE_ENV === 'production') {
//   Vue.prototype.baseURL = 'http://ivdapi.mai47.com/api'
// }
// if (process.env.NODE_ENV === 'test') {
//   Vue.prototype.baseURL = 'http://test.ivdapi.mai47.com/api'
// }

console.log(' process.env.NODE_ENV ', process.env.NODE_ENV)
import VueCropper from 'vue-cropper'
import 'element-ui/lib/theme-chalk/display.css'
Vue.use(VueCropper)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
