<template>
  <div v-if="tags.length" class="SearchKeys m-tb-10">
    <el-tag
      v-for="tag in tags"
      :key="tag.name"
      closable
      class="m-right-10"
      size="mini"
      :type="randomTypes()"
      @close="closeTag(tag)"
    >
      {{ tag.name }}:  {{ tag.value }}
    </el-tag>

  </div>
</template>
<script>
export default {
  name: 'SearchKeys',
  props: {
    searchKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tags: []
    }
  },
  watch: {
    searchKeys: {
      handler(a) {
        this.tags = []
        console.log(a)
        this.tags = a
      },
      deep: true
    }
  },
  methods: {
    randomTypes() {
      const arr = ['', 'success', 'info', 'warning', 'danger']
      const randomNum = Math.random() * 4
      return arr[randomNum]
    },
    // 清除某个筛选条件
    closeTag(tag) {
      this.$emit('close', tag)
    }
  }
}
</script>

