class MinCommon {
  /**
     * @description 隐藏部分手机号码
     */
  static hideTel(value) {
    return value.replace(/(.{3}).+(.{4})/, '$1****$2')
  }

  /**
     * @description 隐藏部分身份证号码
     */
  static hideIdCar(value) {
    return value.replace(/(.{3}).+(.{4})/, '$1*********$2')
  }

  /**
     * @description 每隔 4位 加 ' '
     */
  static pattern4Right(value, pattern = ' ') {
    return value.replace(/\B(?=(\d{4})+(?!\d))/g, pattern)
  }

  /**
     * @description 每隔 4位 减少 ' '
     */
  static reduction4(value) {
    return value.replace(/\$\s?|( *)/g, '')
  }

  /**
     * @description 清除两边的空格
     */
  static trim(value) {
    return value.trim()
  }
  /**
   *
   * @param {*} mobile
   * @returns
   */

  static isRealNum(val) {
    // isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
    if (val === '' || val == null) {
      return false
    }
    if (!isNaN(val)) {
      // 对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
      // 所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
      return true
    } else {
      return false
    }
  }

  /**
     * @description 判断是否是11位电话号码
     */
  static checkMobile(mobile) {
    return (/^1[345789]\d{9}$/.test(mobile))
  }

  /**
     * @description 时间格式化
     * date: 时间对象
     * fmt: 转换格式： yyyy-MM-dd hh:mm:ss 或 yyyy/MM/dd hh:mm:ss
     */
  static formatDate(date, fmt) {
    if (date.getTime() <= 0) return ''
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  }

  static formatDate1(_date, fmt) {
    if (!_date) return ''
    const date = new Date(_date)
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (const k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        const str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : ('00' + str).substr(str.length))
      }
    }
    return fmt
  }

  /**
     * @param callback 回调函数
     * @param countdown 倒计时时间 默认 60s
     * @param interval 时间间隔 默认1000ms
     * @description 倒计时
     */
  static setCountDown(callback, countdown = 59, interval = 1000) {
    callback(countdown)
    const timer = setInterval(() => {
      countdown--
      if (countdown < 1) {
        clearInterval(timer)
      }
      callback(countdown, timer)
    }, interval)
  }

  /**
     * @description 防抖
     * @param callback 回调函数
     * @param interval 时间间隔 默认500ms
     * @param immediate true 表立即执行，false 表非立即执行 默认true
     */
  static debounce(callback, interval = 500, immediate = true) {
    let timer
    return function() {
      const args = arguments
      if (timer) clearTimeout(timer)
      if (immediate) {
        const flag = !timer
        timer = setTimeout(() => {
          timer = null
        }, interval)
        if (flag) callback.apply(this, args)
      } else {
        timer = setTimeout(() => {
          callback.apply(this, args)
        }, interval)
      }
    }
  }

  /**
     * 数组去重
    */
  static arrSet(arr) {
    return Array.from(new Set(arr))
  }

  /**
   *
   * @param {data}  后台返回字符
   * @param {filename} 文件名称
   *
   */
  static S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }
  static guid() {
    return (this.S4() + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + '-' + this.S4() + this.S4() + this.S4())
  }
  // 处理字节流
  static downloadFile(data, name, type) {
    console.log(data, name, type)
    var byteString = atob(data)
    var arrayBuffer = new ArrayBuffer(byteString.length) // 创建缓冲数组
    var intArray = new Uint8Array(arrayBuffer) // 创建视图
    for (var i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i)
    }
    let _type = ''
    if (type && type.indexOf('xls') > -1) {
      _type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
    }
    const blob = new Blob([intArray], { type: _type })
    // 获取heads中的filename文件名
    const downloadElement = document.createElement('a')
    // 创建下载的链接
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    const name_ = name || this.guid()
    // 下载后文件名
    downloadElement.download = name_ + `${type || '.xls'}`
    document.body.appendChild(downloadElement)
    // 点击下载
    downloadElement.click()
    // 下载完成移除元素
    document.body.removeChild(downloadElement)
    // 释放掉blob对象
    window.URL.revokeObjectURL(href)
  }
  /**
   *
   * @param value
   * 查看数组中是否包含某项
   */
  static getArrayValue(array, name, value) {
    let flag = null
    array.map(item => {
      if (item[name] === value) return flag = false
      return flag = true
    })
    return flag
  }
  // 获取导出名称
  static getExportName(str) {
    if (!str) return str
    return str + this.formatDate(new Date(), 'hh:mm:ss')
  }

  /**
     *  返回星期几
     *
    */
  static getDay(num) {
    switch (num) {
      case 0:
        return '星期日'
      case 1:
        return '星期一'

      case 2:
        return '星期二'

      case 3:
        return '星期三'

      case 4:
        return '星期四'

      case 5:
        return '星期五'

      case 6:
        return '星期六'
    }
  }

  // 校验是否有数据， 没有返回false 有返回true
  // val 当前值 data 数据源
  static checkMeau(val, data) {
    return data.filter(item => item.ElementID === val).length
  }

  static SetTableHeight(consHeight) {
    // 屏幕高度
    const heihgt = document.body.clientHeight
    return heihgt - consHeight
  }

  // 处理缓存
  static setCache(delkey, this_) {
    if (this_.$vnode.parent.componentInstance.keys.length && delkey) {
      return this_.$vnode.parent.componentInstance.keys = this_.$vnode.parent.componentInstance.keys.filter(item => item !== delkey)
    }
  }

  // 修改日期时间
  static setDateFormate(str) {
    if (!str) return str
    return str.split(' ')[0]
  }
}

MinCommon.install = function(Vue) {
  Vue.prototype.$minCommon = MinCommon
}

export default MinCommon
