<template>
  <div class="FieldManagement" style="z-index:9999">
    <el-dialog id="qwwqeeqweqw" :close-on-click-modal="false" title="字段管理" :visible.sync="setFieldManagement" :show-close="true" :width="width_" :append-to-body="true">
      <div class="p-lr-20 t_line_s p-top-20">
        <!--   :closable="true"
          close-text="知道了" -->
        <el-alert
          :closable="false"
          title="拖拽改变列的顺序，点击开关改变列的显示情况"
          type="warning"
        />
      </div>
      <div class="FieldManagementMain">
        <div id="mainList" class="mainList">
          <vuedraggable v-model="list_" filter=".forbid" :move="onMove" class="wrapper" animation="60">
            <transition-group id="transition-group" class="transition-group">
              <!-- min-border-bottom flex j-start a-center  span-->
              <div v-for="(item,index) in list_" :key="index" :class="item.isShow ? 'forbid item  select_none ':'min-border item   select_none cursor'">
                <el-switch
                  v-if="!item.isShow"
                  v-model="item.isChecked"
                  active-color="#409EFF"
                  inactive-color="#ccc"
                />
                <span
                  v-if="!item.isShow"
                  style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: $line;
                  -webkit-box-orient: vertical;
                  "
                  class="Name el-1"
                >{{ item.label }}</span>

              </div>
            </transition-group>

          </vuedraggable>
        </div>
        <div class="caozuo t-right p-top-20 p-right-20 t_line_s m-top-20">
          <el-button @click="() => setFieldManagement = false">取消</el-button>
          <el-button type="primary" @click="saveFieldManagement">保存</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import vuedraggable from 'vuedraggable'
// import tableMixin from '@/minxin/table-minxin'
export default {
  name: 'FieldManagement',
  components: {
    vuedraggable
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    actionName: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    selectType: {
      type: [Number, String],
      default: 0
    },
    // eslint-disable-next-line vue/require-default-prop
    changeListTable: Function
  },
  data() {
    return {
      setFieldManagement: false,
      list_: [],
      width_: '60%'
    }
  },
  watch: {
    setFieldManagement(e) {
      if (e) {
        this.$tableListName = this.name
        this.list_ = JSON.parse(JSON.stringify(this.list))
        this.$nextTick(() => {
          this.setArrWindow(this.list_.length)
        })
        return
      }
      this.list_ = []
    }
  },
  mounted() {
    console.log(' this.$tableListName ', this.$tableListName)
    const that = this
    window.onresize = () => {
      return (() => {
        that.setArrWindow(that.list_.length, that.width)
        this.setDialogWidth()
      })()
    }
  },
  methods: {
    setDialogWidth() {
      var val = document.body.clientWidth
      const def = 1250 //
      if (val < def) {
        this.width_ = '80%'
      } else {
        this.width_ = '60%'
      }
    },
    onMove(e) {
      if (e.relatedContext.element.isShow) return false
      return true
    },
    setArrWindow(length) {
      if (length % 4 !== 0) {
        this.list_.push({ isShow: true })
        this.setArrWindow(this.list_.length)
        return this.list_
      }
      return this.list_
    },
    openSetFieldManagement() {
      this.setFieldManagement = true
      this.setDialogWidth()
    },
    saveFieldManagement() {
      this.setFieldManagement = false
      this.changeListTable(this.list_, this.name, this.actionName, this.selectType)
    }
  }
}
</script>
<style>
.PageHeader2{width: 100%;height: 50px; background-color: #fff;position: absolute;left: 0;top:0;display: flex;color:#006fda;

}
</style>
<style lang="scss" scoped>
.FieldManagementMain {
    height: auto;
    .mainList {
        widows: 100%;
        height: auto;
        display: flex;
        overflow: auto;

        .wrapper {
            width: 100%;
            height: 100%;
            margin: 0 20px;
          .transition-group {

            // height: auto;
            //  margin: 0 30px;
            //  background-color: red;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .item {
              width:calc((100% - 40px) / 4);
              margin-top: 15px;
              // display: flex;
              flex-direction: column;
              justify-content: center;
              box-sizing: border-box;
              padding:5px 7px;
              // border: 1px #eee solid;
              border-radius: 50px;
              .el-switch{ padding-left: 0; width:40px; display: inline-block; float: left;box-sizing: border-box}
              .Name{ width: calc(100% - 50px); float:left; padding-left:10px;display: inline-block; box-sizing: border-box; line-height: 22px;}
            }
          }
        }

    }
    .btn {
        height: 50px;
    }
    .aaa {
      height: 0.5px;
    }

}
</style>
