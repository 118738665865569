<template>
  <div class="Guidepage" style="z-index:9999">
    <!-- 首营审批流程管理 start-->
    <el-dialog
      v-if="type === 1"
      class="GuidepageStyle DepartmentBox"
      title=""
      :visible.sync="centerDialogVisible"
      :width="width_"
      center
      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">首营审批管理流程</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="closeGuidepagedialog()" />
      </div>
      <div class="ViewGuidepage m-tb-30 flex  a-center j-center">

        <div class="flex j-start a-center m-top-25">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('Approval')">
            <i class="icon8 f30" />
            <span>审批流程设置</span>
          </div>
        </div>
        <div class="line-left">
          <div class="line-l" />
          <div class="line-main">
            <div class="line-s" />
          </div>
          <div class="line-aa">
            <div class="line-j-t" />
            <div class="line-j-b" />
          </div>
        </div>
        <div class="two_view flex column j-between">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('Merchants1',{ from: 'Guidepage',type:'发起首营审批' } )">
            <i class="icon1 f30" />
            <span>发起企业首营审批</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" @click.stop="toPage('ProductList',{ from: 'Guidepage' } )">
            <i class="icon1 f30" />
            <span>发起产品首营审批</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex j-start a-center m-top-25 m-left-10">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('MyApproval' )">
            <i class="icon2 f30" />
            <span>审核</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: end;">
        <el-checkbox v-model="isShow">不再显示此窗口</el-checkbox>
      </div>
    </el-dialog>
    <!--  首营审批流程管理 end-->
    <!--  仓储管理 start-->
    <el-dialog
      v-if="type === 2"
      title=""
      custom-class="GuidepageStyle DepartmentBox"
      :visible.sync="centerDialogVisible"
      :width="width_"
      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">仓库管理流程</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="closeGuidepagedialog()" />
      </div>
      <div class="ViewGuidepage   m-tb-30 flex  a-center j-center">
        <div class="flex j-start a-start " style="height:305px">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('purchase-order')">
            <i class="icon5 f30" />
            <span>采购预收货</span>
          </div>
        </div>
        <div class="flex j-start a-start " style="height:252px">
          <div class="line-j" />
        </div>
        <div class="two_view flex j-between column a-center">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('goods-warehousing')">
            <i class="icon7 f30" />
            <span>采购入库</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" @click.stop="toPage('other-warehousing')">
            <i class="icon7 f30" />
            <span>其他方式入库</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex j-start a-center">
          <div class="flex column j-center a-center cursor m-top-25" @click.stop="toPage('warehousing-record')">
            <i class="icon8 f30" />
            <span>入库记录单</span>
          </div>
        </div>
        <div class="line-left">
          <div class="line-l" />
          <div class="line-main">
            <div class="line-s" />
          </div>
          <div class="line-aa">
            <div class="line-j-t" />
            <div class="line-j-b" />
          </div>
        </div>
        <div class=" two_view flex column j-between">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('delivery-goods')">
            <i class="icon6 f30" />
            <span>销售出库</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" @click.stop="toPage('other-delivery')">
            <i class="icon6 f30" />
            <span>其他方式出库</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex column j-center a-center cursor  m-top-25" @click.stop="toPage('delivery-record')">
          <i class="icon8 f30" />
          <span>出库记录单</span>
        </div>
        <div class="flex j-center a-center">
          <div class="line-j" />
          <div class="flex column j-center a-center cursor  m-top-25 m-left-10" @click.stop="toPage('house-management')">
            <i class="icon9 f30" />
            <span>库存报表</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: end;">
        <el-checkbox v-model="isShow">不再显示此窗口</el-checkbox>
      </div>
    </el-dialog>
    <!--  仓储管理 end-->
    <!-- 订单管理 start -->
    <el-dialog
      v-if="type === 3"
      class="GuidepageStyle DepartmentBox"
      title=""
      :visible.sync="centerDialogVisible"
      :width="width_"

      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">销售业务流程</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="closeGuidepagedialog()" />
      </div>
      <div class="ViewGuidepage   m-tb-30 flex  a-center j-center">
        <div class="two_view flex j-between column a-center m-right-10">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('Merchants1',{ from: 'Guidepage' } )">
            <i class="icon1 f30" />
            <span>客户首营</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" @click.stop="toPage('ProductList',{ from: 'Guidepage' } )">
            <i class="icon1 f30" />
            <span>产品首营</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex j-start a-center  m-lr-10 m-top-25">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('MyApproval' )">
            <i class="icon2 f30" />
            <span>审批管理</span>
          </div>
        </div>
        <div class="flex j-center a-center " style="height:240px">
          <div class="line-j" />
          <div class="flex column j-center a-center cursor m-top-25" @click.stop="toPage('salesContract2',{from: 'Guidepage',contractType: 2 })">
            <i class="icon3 f30" />
            <span>创建销售合约</span>
          </div>
        </div>
        <div class="line-left">
          <div class="line-l" />
          <div class="line-main">
            <div class="line-s" />
          </div>
          <div class="line-aa">
            <div class="line-j-t" />
            <div class="line-j-b" />
          </div>
        </div>
        <div class="flex j-between column a-center two_view">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('orderCustomers')">
            <i class="icon2 f30" />
            <span>代客下单</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" style="cursor:not-allowed">
            <i class="icon4 f30" />
            <span>客户自主下单</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex j-center a-center  m-lr-10 m-top-25">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('orderList')">
            <i class="icon3 f30" />
            <span>销售单发货</span>
          </div>
        </div>
        <div class="flex j-center a-center" style="height:240px">
          <div class="line-j   m-right-10" />
          <div class="flex column j-center a-center cursor m-top-25" @click.stop="toPage('delivery-goods')">
            <i class="icon6 f30" />
            <span>销售出库</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: end;">
        <el-checkbox v-model="isShow">不再显示此窗口</el-checkbox>
      </div>
    </el-dialog>
    <!-- 订单管理 end -->
    <!-- 采购管理流程 start -->
    <el-dialog
      v-if="type === 4"
      class="GuidepageStyle DepartmentBox"
      title=""
      :visible.sync="centerDialogVisible"
      :width="width_"
      :show-close="false"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">采购业务流程</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="closeGuidepagedialog()" />
      </div>
      <div class="ViewGuidepage m-tb-30 flex a-center j-center">
        <div class="flex j-between column a-center two_view">
          <div class="flex column j-center a-center cursor two_view_top" @click.stop="toPage('Merchants1',{ from: 'Guidepage' } )">
            <i class="icon2 f30" />
            <span>供应商首营</span>
          </div>
          <div class="flex column j-center a-center cursor two_view_bottom" @click.stop="toPage('ProductList',{ from: 'Guidepage' } )">
            <i class="icon2 f30" />
            <span>产品首营</span>
          </div>
        </div>
        <div class="line-right">
          <div class="line-tb">
            <div class="line-j-r-t" />
            <div class="line-j-r-b" />
          </div>
          <div class="line-r-c">
            <div class="line-r" />
            <div class="line-j-r-b-c" />
          </div>
        </div>
        <div class="flex j-start a-center  m-lr-10 m-top-25">
          <div class="flex column j-center a-center cursor" @click.stop="toPage('MyApproval' )">
            <i class="icon2 f30" />
            <span>审批管理</span>
          </div>
        </div>
        <div class="flex j-center a-center" style="height:240px">
          <div class="line-j" />
          <div class="flex column j-center a-center cursor  m-top-25" @click.stop="toPage('salesContract1',{from: 'Guidepage',contractType: 1 })">
            <i class="icon2 f30" />
            <span>创建采购合约</span>
          </div>
        </div>
        <div class="flex j-center a-center" style="height:240px">
          <div class="line-j" />
          <div class="flex column j-center a-center cursor  m-top-25" @click.stop="toPage('purchaseRequest',{from: 'Guidepage' })">
            <i class="icon2 f30" />
            <span>创建采购申请</span>
          </div>
        </div>
        <div class="flex j-center a-center" style="height:240px">
          <div class="line-j" />
          <div class="flex column j-center a-center cursor  m-top-25" @click.stop="toPage('purchase-order')">
            <i class="icon2 f30" />
            <span>采购预收货</span>
          </div>
        </div>
        <div class="flex j-center a-center " style="height:240px">
          <div class="line-j  m-right-10" />
          <div class="flex column j-center a-center cursor  m-top-25" @click.stop="toPage('goods-warehousing')">
            <i class="icon2 f30" />
            <span>采购入库</span>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: end;">
        <el-checkbox v-model="isShow">不再显示此窗口</el-checkbox>
      </div>
    </el-dialog>
    <!-- 采购管理流程 end -->

  </div>
</template>
<script>
export default {
  name: 'Guidepage',
  props: {
    title: {
      type: String,
      default: '引导页名称'
    },
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      width_: '1050px',
      isShow: false,
      centerDialogVisible: false
    }
  },
  mounted() {
    // const that = this
    window.onresize = () => {
      return (() => {
        this.setDialogWidth()
      })()
    }
  },
  methods: {
    setDialogWidth() {
      // var val = document.body.clientWidth
      // const def = 1400
      // if (val < def) {
      //   this.width_ = '95%'
      // } else {
      //   this.width_ = '70%'
      // }
    },
    openGuidepagedialog() {
      this.centerDialogVisible = true
      this.setDialogWidth()
    },
    closeGuidepagedialog() {
      this.$emit('close')
      this.centerDialogVisible = false
      if (this.isShow) {
        this.$api.UserLeadRecord({
          MeauName: this.title
        }).then(res => {
          this.isShow = false
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('设置成功')
        }).catch(err => {
          this.$message.error('错误：' + err)
          this.isShow = false
        })
      }
    },
    toPage(name, data) {
      this.$router.push({ name, params: data })
      this.closeGuidepagedialog()
    }
  }
}
</script>
<style lang="scss" scoped>
// @media screen and (max-width: 1300px) {
//   .GuidepageStyle {
//     width: 90%;
//   }
// }

.icon1 {
  width: 48px;
  height: 48px;
  background-image: url('../static/1.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon2 {
  width: 48px;
  height: 48px;
  background-image: url('../static/2.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon3 {
  width: 48px;
  height: 48px;
  background-image: url('../static/3.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon4 {
  width: 48px;
  height: 48px;
  background-image: url('../static/4.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon5 {
  width: 48px;
  height: 48px;
  background-image: url('../static/5.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon6 {
  width: 48px;
  height: 48px;
  background-image: url('../static/6.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon7 {
  width: 48px;
  height: 48px;
  background-image: url('../static/7.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon8 {
  width: 48px;
  height: 48px;
  background-image: url('../static/8.png');
  background-size: cover;
  margin-bottom: 5px;
}
.icon9 {
  width: 48px;
  height: 48px;
  background-image: url('../static/9.png');
  background-size: cover;
  margin-bottom: 5px;
}
.ViewGuidepage {
  width: auto;
  height: 400px;
}
.Guidepagedialog {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
}

  .line-j {
    width: 70px;
    height: 17px;
    background-image: url('../static/right_a.png');
  }
  .line-left {
    width: auto;
    height: 240px;
    display: flex;
    // position: relative;
    align-items: center;
        .line-l {
          width: 70px;
              height: 3px;
              background-color: #ccc;
              // position: absolute;
              // left: -70px;
              // top: 117px;
        }
        .line-main {
          width: 3px;
          height: 240px;
           .line-s {
              width: 3px;
              height: 100%;
              background-color: #ccc;
              // position: absolute;
              // left: 0;
              // top: 0;
            }
        }
        .line-aa {
          height: 254px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .line-j-t {
            //  position: absolute;
            //  top: -7px;
            //  left: 3px;
            width: 70px;
            height: 17px;
            background-image: url('../static/right_a.png');
          }
          .line-j-b {
            //  position: absolute;
            //  bottom: -7px;
            //  left: 3px;
            width: 70px;
            height: 17px;
            background-image: url('../static/right_a.png');
          }
        }

  }
  .line-right {
    width: auto;
    height: 240px;
    // position: relative;
    display: flex;
    .line-tb {
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      .line-j-r-t {
         width: 70px;
         height: 3px;
         background-color: #ccc;
       }
       .line-j-r-b {
         width: 70px;
         height: 3px;
         background-color: #ccc;
       }
    }
  .line-r-c{
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
        .line-r {
          width: 3px;
          height: 100%;
          background-color: #ccc;
        }
        .line-j-r-b-c {
          width: 70px;
          height: 17px;
          background-image: url('../static/right_a.png');
        }
  }

  }
  .two_view {
    height: 350px;
    .two_view_top {
      height: 115px;
    }
    .two_view_bottom {
      width: auto;
      height: 100px;
    }
  }

</style>
