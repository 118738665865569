import { render, staticRenderFns } from "./setPsd.vue?vue&type=template&id=4de70c74&scoped=true&"
import script from "./setPsd.vue?vue&type=script&lang=js&"
export * from "./setPsd.vue?vue&type=script&lang=js&"
import style0 from "./setPsd.vue?vue&type=style&index=0&id=4de70c74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4de70c74",
  null
  
)

export default component.exports