import { post, get } from './request'
export default {
  // 获取右边的权限
  GetPermissionList: data => post('/permission/GetPermissionList', data),
  // 设置部门的权限
  SetDeptPermission: data => post('/permission/SetDeptPermission', data),
  // 设置角色的权限
  SetRolePermission: data => post('/permission/SetRolePermission', data),
  // 设置人员的权限
  SetUserPermission: data => post('/permission/SetUserPermission', data),
  // 获取人员可访问权限
  GetUserTierMenuList: data => get('/permission/GetUserTierMenuList', data),
  // 根据页面标识获取人员按钮权限
  GetUserButtonListByMenuElementID: data => get('/permission/GetUserButtonListByMenuElementID', data)
}
