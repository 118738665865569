export default {
  namespaced: true,
  state: {
    carList: [],
    cachedViews: [], // 需要缓存的组件name
    tags: [], // 已选择的组件name
    path: '',
    isLodingComponent: false, // 搜索组件提交按钮loading
    allpageLoading: true, // 公用页面的loading
    formJson: {}, // 企业入驻的缓存
    isFullPath: '', // 切换tags
    printTemplateBase: { // 自定义打印模板基础数据  非新增或删除  不要做改动
      titleArr: [
        { label: '客户名称', prop: 'SendCompanyName', isChecked: true },
        { label: '供应商名称', prop: 'SellCompanyName', isChecked: false },
        { label: '销售出货单', prop: 'desc', isChecked: true }
      ],
      header: [
        { label: '送达方', prop: 'SendCompanyName', isChecked: true, value: '', width: 42 },
        { label: 'WMS单号', prop: 'WMSOrderNo', isChecked: true, value: '', width: 35 },
        { label: '发票号码', prop: 'InvoiceNo', isChecked: true, value: '', width: 20 },
        { label: '售达方', prop: 'AgentCompanyName', isChecked: true, value: '', width: 42 },
        { label: '发货单号', prop: 'DeliveryID', isChecked: true, value: '', width: 35 },
        { label: '出库日期', prop: 'DeliveryTimeFormat', isChecked: true, value: '', width: 20 },
        { label: '送货地址', prop: 'SendAddress', isChecked: true, value: '', width: 42 },
        { label: '币别', prop: 'CurrencyType', isChecked: true, value: '', width: 55 },
        // 以上为默认展示
        { label: '订单号', prop: 'OrderID', isChecked: false, value: '', width: 35 },
        { label: '采购单编号', prop: 'SimsOrderID', isChecked: false, value: '', width: 35 },
        { label: '开票日期', prop: 'InvoiceDateFormat', isChecked: false, value: '', width: 35 },
        { label: '卖方SAP交货单号', prop: 'SapDeliveryID', isChecked: false, value: '', width: 20 },
        { label: '快递名称', prop: 'ExpressName', isChecked: false, value: '', width: 42 },
        { label: '快递单号', prop: 'ExpressNo', isChecked: false, value: '', width: 20 },
        { label: '供应商', prop: 'SellCompanyName', isChecked: false, value: '', width: 35 },
        { label: '供货单位地址', prop: 'SellCompanyAddress', isChecked: false, value: '', width: 42 },
        { label: '供货单位开户银行', prop: 'SellBankName', isChecked: false, value: '', width: 20 },
        { label: '供货单位账号', prop: 'SellBankAccountNo', isChecked: false, value: '', width: 35 },
        { label: '物流单号', prop: 'LogisticsCode', isChecked: false, value: '', width: 35 },
        { label: '订单备注', prop: 'OrderRemark', isChecked: false, value: '', width: 20 },
        { label: '交货单创建时间', prop: 'WriteTimeFormat', isChecked: false, value: '', width: 42 },
        { label: '订单创建时间', prop: 'OrderWriteTimeFormat', isChecked: false, value: '', width: 20 },
        { label: '拣货时间', prop: 'PickedTimeFormat', isChecked: false, value: '', width: 20 }
      ],
      header_desc: { label: '我是第一个描述的数据', isChecked: false },
      table: [
        { label: '物料编号', isChecked: true, prop: 'InvCode' },
        { label: '原厂编号', isChecked: true, prop: 'ManuPartNum' },
        { label: '物料名称', isChecked: true, prop: 'MaterialName' },
        { label: '品牌', isChecked: true, prop: 'BrandName' },
        { label: '规格\/型号', isChecked: true, prop: 'Specification' },
        { label: '单位', isChecked: true, prop: 'StockUnitN' },
        { label: '物料映射编号', isChecked: true, prop: 'CustomInvCode' },
        { label: '标类', isChecked: true, prop: 'StandardType' },
        { label: '批号', isChecked: true, prop: 'LotNum' },
        { label: '生产日期', isChecked: true, prop: 'ProductDateFormat' },
        { label: '有效截止', isChecked: true, prop: 'ExpiredDateFormat' },
        { label: '数量', isChecked: true, prop: 'DeliveryNumFormat' },
        { label: '含税单价', isChecked: true, prop: 'SapKBETR2Format' },
        { label: '含税金额', isChecked: true, prop: 'SapKWERTFormat' },
        { label: '注册证号', isChecked: true, prop: 'RegisterNo' },
        { label: '生产企业许可证', isChecked: true, prop: 'ProductionLicence' },
        { label: '生产企业', isChecked: true, prop: 'ProductFactory' },
        { label: '储存', isChecked: true, prop: 'StorageConditionName' },
        // 以上为默认展示的字段
        { label: '物料类型', isChecked: false, prop: 'MaterialTypeName' },
        { label: '注册证失效期', isChecked: false, prop: 'RegisterEndFormat' },
        { label: '序号', isChecked: false, prop: 'ROWNUM' },
        { label: '发票号码', isChecked: false, prop: 'InvoiceNo' },
        { label: '订购数量', isChecked: false, prop: 'OrderNumFormat' },
        { label: '物料子类型', isChecked: false, prop: 'MaterialSubTypeName' },
        { label: '批次', isChecked: false, prop: 'LotNo' },
        { label: '交货单号', isChecked: false, prop: 'DeliveryID' },
        { label: '使用科室', isChecked: false, prop: 'SimsSectionName' },
        { label: '订单明细备注', isChecked: false, prop: 'ODIRemark' },
        { label: '交货单明细时间', isChecked: false, prop: 'WriteTimeFormat' }

      ],
      foot_table: [
        { label: '备注', isChecked: true, prop: 'OrderRemark' },
        { label: '总金额', isChecked: true, prop: 'SumSapKWERTFormat' }
      ],
      footer_desc: { label: '我是第二个描述的数据', isChecked: false },
      footer: [
        { label: '发票号', prop: '', isChecked: true, value: '', width: 20 },
        { label: '制单', prop: '', isChecked: true, value: '', width: 20 },
        { label: '货物签收', prop: '', isChecked: true, value: '', width: 20 },
        { label: '发票签收', prop: '', isChecked: true, value: '', width: 20 },
        { label: '货票签收', prop: '', isChecked: true, value: '', width: 20 },
        { label: '到货温度', prop: '', isChecked: true, value: '', width: 20 },
        { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
        { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 },
        { label: '签收日期', prop: '', isChecked: true, value: '', width: 20 }

      ]
    }
  },
  mutations: {
    setCarList(state, data) {
      state.carList = data
    },
    setCachedViews(state, data) {
      state.cachedViews = data
    },
    setTags(state, data) {
      state.tags = data
    },
    setPath(state, data) {
      state.path = data
    },
    setisLodingComponent(state, data) {
      state.isLodingComponent = data
    },
    setallpageLoading(state, data) {
      state.allpageLoading = data
    },
    setJson(state, data) {
      state.formJson = data
    },
    setFullPath(state, data) {
      state.isFullPath = data
    }
  },
  actions: {
    setCarList({ commit }, data) {
      commit('setCarList', data)
    },
    setFullPath({ commit }, data) {
      commit('setFullPath', data)
    },
    setCachedViews({ commit }, data) {
      commit('setCachedViews', data)
    },
    setTags({ commit }, data) {
      console.log('保存已选状态的组件', data)
      commit('setTags', data)
    },
    setPath({ commit }, data) {
      commit('setPath', data)
    },
    setisLodingComponent({ commit }, data) {
      commit('setisLodingComponent', data)
    },
    setallpageLoading({ commit }, data) {
      commit('setallpageLoading', data)
    },
    setJson({ commit }, data) {
      commit('setJson', data)
    }
  }
}
