import axios from 'axios'
import { Message } from 'element-ui'
// import Cookies from 'js-cookie'
let baseURL
if (process.env.NODE_ENV === 'development') {
  baseURL = 'http://test.saas.api.mai47.com/api'
  // baseURL = 'http://local.api.saas.mai47.com/api'
  // baseURL = 'http://local.saas.api.mai47.com/api'
}
if (process.env.NODE_ENV === 'production') {
  // baseURL = 'http://production.saas.api.mai47.com'
  baseURL = 'https://ivdapi.mai47.com/api'
}
if (process.env.NODE_ENV === 'test') {
  // baseURL = 'http://production.saas.api.mai47.com'
  // baseURL = 'http://test.saas.api.mai47.com/api'
  baseURL = 'http://test.ivdapi.mai47.com/api'
}
// 请求取消
// const CancelToken = axios.CancelToken
// let cancelBack = null
// var isCancel = false
const service = axios.create({
  baseURL: baseURL // api的base_url
  // 取消请求
  // transformRequest: data => qs.stringify(data)
})
// response interceptor  请求拦截
service.interceptors.request.use((config) => {
  var reg = new RegExp('(^| )' + 'token' + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  config.headers['Authorization'] = arr ? arr[2] : '' // 请求头携带token  token暂时用h5存储
  // if (!config.headers['Authorization']) return cancelBack()
  // if (isCancel) return cancelBack()
  return config
}, err => Promise.reject(err))
// response interceptor（接收拦截器）
service.interceptors.response.use((response) => {
  // console.log(response)
  const res = response.data
  // RetMsg: "token 无效"
  if (res.RetCode === '401') {
    // isCancel = true
    Message({ type: 'error', message: res.RetMsg })
    localStorage.removeItem('token')
    var keys = document.cookie.match(/[^ =;]+(?=\=)/g)
    if (keys) {
      for (var i = keys.length; i--;) { document.cookie = keys[i] + '=0;expires=' + new Date(0).toUTCString() }
    }
    const _ticketarr = new RegExp('(^| )' + 'RundaB2BTicket' + '=([^;]*)(;|$)')
    const _ticket = document.cookie.match(_ticketarr)
    sessionStorage.clear()
    axios.post(`${baseURL}/logOut`, {
      _ticket: _ticket[2]
    }).then(res => {
      if (res.data.RetCode !== '0') return Message({ type: 'error', message: res.data.RetMsg })
      return window.location.href = process.env.NODE_ENV === 'production' ? 'https://www.mai47.com' : 'http://test.buy.mai47.com'
    })
  }

  return res
}, err => {
  Message({ type: 'error', message: err })
  return Promise.reject(err)
})

export default service
