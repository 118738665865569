<template>
  <div class="exportLoading">
    <el-dialog class="DepartmentBox" destroy-on-close :visible.sync="showExportLoading" :show-close="false" width="600px" :append-to-body="true" :close-on-click-modal="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">导出提示</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="close" />
      </div>
      <div v-if="!successShow" class="flex j-center a-center p-tb-20 f16 column">
        <div class="flex a-center">  <i class="el-icon-loading m-lr-10" />  正在整理要导出数据，请稍后...</div>
        <span class="f12 p-top-5" style="color: #ccc"> <i class="m-lr-10" />  您可以离开此页面，导出将在后台继续进行，如长时间没有文件导出，请重新尝试</span>
      </div>
      <div v-if="successShow" class="flex j-center p-tb-20 f16 ">
        <div class="m-right-10">
          <i class="el-icon-circle-check m-lr-10 f20" style="color: #0E9A00;font-size:30px" />
        </div>
        <div class="flex  column">
          表格数据导出成功
          <span class="f14 p-top-5" style="color: #999">如未自动下载文件，请手动下载：</span>
          <span class="f14 p-top-5 cursor" style="color: #409EFF" @click="down">{{ msg.name }}.xls</span>
        </div>

      </div>
      <div class="flex j-end p-bottom-10" @click="() => showExportLoading = false">
        <el-button size="mini" type="primary">完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ExportLoading',
  // props: {
  //   showExportLoading: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data() {
    return {
      showExportLoading: false,
      successShow: false,
      msg: ''
    }
  },
  methods: {
    open() {
      this.showExportLoading = false
      this.successShow = false
      this.showExportLoading = !this.showExportLoading
    },
    success(value, name) {
      this.msg = { value, name }
      this.showExportLoading = false
      this.showExportLoading = true
      this.successShow = true
    },
    down() {
      if (!this.msg.value) return this.$message.error('错误：下载文件错误')
      this.$minCommon.downloadFile(this.msg.value, this.msg.name)
    },
    close() {
      this.showExportLoading = false
    }

  }
}
</script>

<style scoped>

</style>
