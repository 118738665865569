const layout = () => import('@/views/Index/index')
const routes = [
  // {
  //   path: '/',
  //   name: 'Index',
  //   component: layout,
  //   children: [{
  //     path: 'info',
  //     component: () => import('@/views/car/index'),
  //     name: 'Info',
  //     fullPath: '/info',
  //     meta: { title: '基础信息', icon: 'el-icon-s-platform' }
  //   }]
  // }, // 首页

  {
    path: '/order',
    name: '',
    meta: {
      title: '订单管理',
      icon: 'el-icon-document-copy'
    },
    component: layout,
    children: [
      {
        path: 'orderMananger/orderList',
        component: () => import('@/views/orderMananger/orderList'),
        name: 'orderList',
        fullPath: '/orderMananger/orderList',
        meta: { title: '订单处理', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'orderMananger/orderDetails/:orderid/:ishandler',
        component: () => import('@/views/orderMananger/orderDetails'),
        name: 'orderDetails1',
        fullPath: '/orderMananger/orderDetails',
        meta: { title: '订单详情', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'orderMananger/orderHandle/:orderid/:ishandler',
        component: () => import('@/views/orderMananger/orderHandle'),
        name: 'orderHandle1',
        fullPath: '/orderMananger/orderHandle',
        meta: { title: '处理订单', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'orderMananger/orderCustomers',
        component: () => import('@/views/orderMananger/orderCustomers'),
        name: 'orderCustomers',
        fullPath: '/orderMananger/orderCustomers',
        meta: { title: '代客下单', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'orderMananger/orderMaterialList',
        component: () => import('@/views/orderMananger/orderMaterialList'),
        name: 'orderMaterialList1',
        fullPath: '/orderMananger/orderMaterialList',
        meta: { title: '订单物料清单', icon: 'el-icon-s-platform', noCache: true },
        hidden: false
      },
      {
        path: 'orderMananger/deliveryNoteReport',
        component: () => import('@/views/orderMananger/deliveryNoteReport'),
        name: 'deliveryNoteReport',
        fullPath: '/orderMananger/deliveryNoteReport',
        meta: { title: '交货单报表', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'orderMananger/PrintDeliveryNote/:idx',
        component: () => import('@/views/orderMananger/PrintDeliveryNote'),
        name: 'PrintDeliveryNote',
        fullPath: '/orderMananger/PrintDeliveryNote',
        meta: { title: '打印交货单二维码', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'orderMananger/orderMaterialQuery',
        component: () => import('@/views/orderMananger/orderMaterialQuery'),
        name: 'orderMaterialQuery',
        fullPath: '/orderMananger/orderMaterialQuery',
        meta: { title: '订单查询', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },
  {
    path: '/purchasingManagement',
    name: '',
    meta: {
      title: '采购管理',
      icon: 'el-icon-shopping-cart-full'
    },
    component: layout,
    children: [
      {
        path: 'purchaseRequest',
        component: () => import('@/views/purchasing-management/purchaseRequest'),
        name: 'purchaseRequest',
        fullPath: '/purchasing-management/purchaseRequest',
        meta: { title: '采购申请', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'purchaseRequestDetail/:IDX',
        component: () => import('@/views/purchasing-management/purchaseRequestDetail'),
        name: 'purchaseRequestDetail',
        fullPath: '/purchasing-management/purchaseRequestDetail',
        meta: { title: '采购申请明细', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      //  OrderPurchaseSapID: "CG0000002386"
      // PurchaseState: 1
      {
        path: 'order-processing/:OrderPurchaseSapID/:PurchaseState',
        component: () => import('@/views/purchasing-management/order-processing'),
        name: 'order-processing',
        fullPath: '/purchasing-management/order-processing',
        meta: { title: '预入库单处理', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'purchase-order',
        component: () => import('@/views/purchasing-management/purchase-order'),
        name: 'purchase-order',
        fullPath: '/purchasing-management/purchase-order',
        meta: { title: '采购单收货', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: '/MaterialConversion', // merchants
        component: () => import('@/views/purchasing-management/materialConversion'),
        name: 'MaterialConversion',
        fullPath: '/purchasing-management/materialConversion',
        meta: { title: '物料转换', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: '/PurchaseMaterials',
        component: () => import('@/views/purchasing-management/purchaseMaterials'),
        name: 'purchaseMaterials',
        fullPath: '/purchasing-management/purchaseMaterials',
        meta: { title: '采购单查询', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: '/warehousing-record',
        component: () => import('@/views/purchasing-management/warehousing-record'),
        name: 'warehousingRecord',
        fullPath: '/purchasing-management/warehousing-record',
        meta: { title: '预收货记录', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },
  {
    path: '/userInfo',
    name: '',
    meta: {
      title: '个人信息',
      icon: 'el-icon-data-analysis'
    },
    component: layout,
    all: true,
    children: [
      {
        path: 'userInfo/EnterprisInfo',
        component: () => import('@/views/userInfo/EnterprisInfo'),
        name: 'EnterprisInfo',
        fullPath: '/userInfo/EnterprisInfo',
        meta: { title: '企业信息', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'userInfo/accountInfo',
        component: () => import('@/views/userInfo/accountInfo'),
        name: 'accountInfo',
        fullPath: '/userInfo/accountInfo',
        meta: { title: '账号信息', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '/house-management',
    name: 'HouseManagement',
    meta: {
      title: '仓储管理',
      icon: 'el-icon-box'
    },
    component: layout,
    children: [
      {
        path: 'goods-warehousing',
        component: () => import('@/views/house-management/goods-warehousing'),
        name: 'goods-warehousing',
        fullPath: '/house-management/goods-warehousing',
        meta: { title: '商品入库', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'delivery-goods',
        component: () => import('@/views/house-management/delivery-goods'),
        name: 'delivery-goods',
        fullPath: '/house-management/delivery-goods',
        meta: { title: '商品出库', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'house-management',
        component: () => import('@/views/house-management/index'),
        name: 'house-management',
        fullPath: '/house-management/index',
        meta: { title: '商品库存', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'Inventory-transfer',
        component: () => import('@/views/house-management/Inventory-transfer'),
        name: 'InventoryTransfer',
        fullPath: '/house-management/Inventory-transfer',
        meta: { title: '库存调拨', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'inventoryCorrection',
        component: () => import('@/views/house-management/inventoryCorrection'),
        name: 'inventoryCorrection',
        fullPath: '/house-management/inventoryCorrection',
        meta: { title: '盘点修正', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'initiate-inventory/:idx',
        component: () => import('@/views/house-management/initiate-inventory'),
        name: 'initiateInventory',
        fullPath: '/house-management/initiate-inventory',
        meta: { title: '发起盘点', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'inventoryDetails/:idx/:status',
        component: () => import('@/views/house-management/inventory-details'),
        name: 'inventoryDetails',
        fullPath: '/house-management/inventory-details',
        meta: { title: '盘点详情', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'InventoryTransferDetail/:IDX',
        component: () => import('@/views/house-management/InventoryTransferDetail'),
        name: 'InventoryTransferDetail',
        fullPath: '/house-management/InventoryTransferDetail',
        meta: { title: '调拨单详情', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'deliveryDetails/:DeliveryId/:StockOutID',
        component: () => import('@/views/house-management/delivery-details'),
        name: 'DeliveryDetails',
        fullPath: '/house-management/DeliveryDetails',
        meta: { title: '商品出库详情', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'newInventory/:IDX',
        component: () => import('@/views/house-management/newInventory'),
        name: 'newInventory',
        fullPath: '/house-management/newInventory',
        meta: { title: '新增调拨', icon: 'el-icon-s-platform', noCache: true }
      },
      // Delivery record
      {
        path: 'delivery-record',
        component: () => import('@/views/house-management/delivery-record'),
        name: 'delivery-record',
        fullPath: '/house-management/delivery-record',
        meta: { title: '出库记录', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'warehousing-record',
        component: () => import('@/views/house-management/warehousing-record'),
        name: 'warehousing-record',
        fullPath: '/house-management/warehousing-record',
        meta: { title: '入库记录', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'other-delivery',
        component: () => import('@/views/house-management/other-delivery'),
        name: 'otherDelivery',
        fullPath: '/house-management/other-delivery',
        meta: { title: '其他出库', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'other-warehousing',
        component: () => import('@/views/house-management/other-warehousing'),
        name: 'other-warehousing',
        fullPath: '/house-management/other-warehousing',
        meta: { title: '其他出库', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'view-ordera/:row',
        component: () => import('@/views/house-management/view-order'),
        name: 'view-ordera',
        fullPath: '/house-management/view-order',
        meta: { title: '查看订单', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'delivery-fwarehouse/:StockOutId/:StockOutType',
        component: () => import('@/views/house-management/delivery-fwarehouse'),
        name: 'delivery-fwarehouse',
        fullPath: '/house-management/delivery-fwarehouse',
        meta: { title: '出库发货', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: '/product-warning',
        component: () => import('@/views/house-management/product-warning'),
        name: 'ProductWarning',
        fullPath: '/house-management/product-warning',
        meta: { title: '产品预警设置', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: '/date-warning',
        component: () => import('@/views/house-management/date-warning'),
        name: 'DateWarning',
        fullPath: '/house-management/date-warning',
        meta: { title: '产品预警设置', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '/contract-salesContract',
    name: '',
    meta: {
      title: '合约管理',
      icon: 'el-icon-document-copy'
    },
    component: layout,
    children: [
      {
        path: 'contract/salesContract?contractType=2',
        component: () => import('@/views/contract/salesContract'),
        name: 'salesContract2',
        fullPath: '/contract/salesContract?contractType=2',
        meta: { title: '销售合约', icon: 'el-icon-s-platform', contractType: 2, noCache: true }
      },
      {
        path: 'contract/salesContract?contractType=1',
        component: () => import('@/views/contract/salesContract'),
        name: 'salesContract1',
        fullPath: '/contract/salesContract?contractType=1',
        meta: { title: '采购合约', icon: 'el-icon-s-platform', contractType: 1, noCache: true }
      },
      {
        path: 'contract/contractDetails/:idx/:type',
        component: () => import('@/views/contract/contractDetails'),
        name: 'contractDetails1',
        fullPath: '/contract/contractDetails',
        meta: { title: '合约详情', icon: 'el-icon-s-platform', noCache: false },
        hidden: true
      },
      {
        path: 'contract/contractMaterialList',
        component: () => import('@/views/contract/contractMaterialList'),
        name: 'contractMaterialList',
        fullPath: '/contract/contractMaterialList',
        meta: { title: '物料合约清单', icon: 'el-icon-s-platform', noCache: false },
        hidden: false
      }
    ]
  },
  {
    path: '/merchants',
    name: 'Merchants',
    meta: {
      title: '首营管理',
      icon: 'el-icon-news'
    },
    component: layout,
    children: [
      {
        path: 'merchants', // merchants
        component: () => import('@/views/merchants/index'),
        name: 'Merchants1',
        fullPath: '/merchants/merchants',
        meta: { title: '客商首营', icon: 'el-icon-s-platform', noCache: true }
      },
      // 完善企业信息
      {
        path: 'newCompy', // merchants
        component: () => import('@/views/merchants/newCompy'),
        name: 'newCompy',
        fullPath: '/merchants/newCompy',
        meta: { title: '完善企业信息', icon: 'el-icon-s-platform', noCache: true }
      },
      // 上传证照
      {
        path: 'upLoadCif', // merchants
        component: () => import('@/views/merchants/upLoadCif'),
        name: 'upLoadCif',
        fullPath: '/merchants/newCompy',
        meta: { title: '上传企业证照', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: '/merchants/detail/:IDX/:TYPE',
        name: 'detail',
        component: () => import('@/views/detail/index'),
        meta: { title: '企业详情', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'customerList',
        fullPath: '/merchants/customer-list',
        component: () => import('@/views/customer-list/index'),
        name: 'customerList',
        meta: { title: '客商列表', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'Product/productList',
        fullPath: '/Product/productList',
        component: () => import('@/views/Product/productList'),
        name: 'ProductList',
        meta: { title: '产品首营', icon: 'el-icon-s-platform', type: 1, noCache: true }
      },
      {
        path: 'Product/productInfoList',
        fullPath: '/Product/productInfoList',
        component: () => import('@/views/Product/productInfoList'),
        name: 'productInfoList',
        meta: { title: '产品列表', icon: 'el-icon-s-platform', type: 2, noCache: true }
      },
      {
        path: 'Product/productEdit/:idx/:type',
        fullPath: '/Product/productEdit',
        component: () => import('@/views/Product/productEdit'),
        name: 'productEdit1',
        meta: { title: '产品编辑', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'Product/uploadProductFiles/:idx/:type',
        fullPath: '/Product/uploadProductFiles',
        component: () => import('@/views/Product/uploadProductFiles'),
        name: 'uploadProductFiles1',
        meta: { title: '产品证照上传', icon: 'el-icon-s-platform' },
        hidden: true
      },
      {
        path: 'product/ProductApprove/:idx/:type',
        fullPath: '/Product/ProductApprove',
        component: () => import('@/views/Product/ProductApprove'),
        name: 'ProductApprove1',
        meta: { title: '产品详情', icon: 'el-icon-s-platform', type: 0, noCache: true },
        hidden: true
      },
      {
        path: 'customer/customerList/:IDX/:active/:TYPE',
        fullPath: '/customerList/edit',
        component: () => import('@/views/customer-list/edit'),
        name: 'Edit1',
        meta: { title: '客商编辑', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'contacts/contactsIndex',
        fullPath: '/contacts/contactsIndex',
        component: () => import('@/views/contacts/contactsIndex'),
        name: 'contactsIndex1',
        meta: { title: '通讯录', icon: 'el-icon-s-platform', noCache: true },
        hidden: true
      },
      {
        path: 'licenseWarning',
        fullPath: '/license-warning/index',
        component: () => import('@/views/license-warning/index'),
        name: 'licenseWarning',
        meta: { title: '证照预警', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },
  {
    path: '/approval-process',
    name: 'approvalProcess',
    meta: {
      title: '审批中心',
      icon: 'el-icon-finished'
    },
    component: layout,
    children: [
      {
        path: 'approval',
        component: () => import('@/views/approval-process/index'),
        name: 'Approval',
        fullPath: '/approval/index',
        meta: { title: '审批流程设置', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'MyApproval',
        component: () => import('@/views/approval-process/my-approval'),
        name: 'MyApproval',
        fullPath: '/approval/my-approval',
        meta: { title: '审批管理', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },

  {
    path: '/reportCenter',
    name: '',
    meta: {
      title: '报表中心',
      icon: 'el-icon-data-analysis'
    },
    component: layout,
    children: [
      {
        path: 'reportCenter/PurchaseAmountReport',
        component: () => import('@/views/report-center/PurchaseAmountReport'),
        name: 'PurchaseAmountReport',
        fullPath: '/report-center/address',
        meta: { title: '采购金额报表', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'reportCenter/SalesAmountReport',
        component: () => import('@/views/report-center/SalesAmountReport'),
        name: 'SalesAmountReport',
        fullPath: '/report-center/SalesAmountReport',
        meta: { title: '销售金额报表', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },

  // {
  //   path: '/jurisdiction',
  //   name: '',
  //   meta: {
  //     title: '权限设置',
  //     icon: 'el-icon-medal'
  //   },
  //   component: layout,
  //   children: [
  //     {
  //       path: 'jurisdiction/jurisdictionIndex',
  //       component: () => import('@/views/jurisdiction/jurisdictionIndex'),
  //       name: 'jurisdictionIndex',
  //       fullPath: '/jurisdiction/jurisdictionIndex',
  //       meta: { title: '权限设置', icon: 'el-icon-medal', contractType: 2 }
  //     }
  //   ]
  // },
  {
    path: '/financial',
    name: '',
    meta: {
      title: '财务模块',
      icon: 'el-icon-setting'
    },
    component: layout,
    children: [
      {
        path: 'Financialmodule/index',
        component: () => import('@/views/financial-module/index'),
        name: 'FinancialModule',
        fullPath: '/financial-module/index',
        meta: { title: '发票管理', icon: 'el-icon-bank-card', noCache: true }
      }
    ]
  },
  {
    path: '/system',
    name: '',
    meta: {
      title: '系统管理',
      icon: 'el-icon-setting'
    },
    component: layout,
    children: [
      {
        path: 'system/address',
        component: () => import('@/views/system-manage/address'),
        name: 'Address',
        fullPath: '/system-manage/address',
        meta: { title: '收货地址管理', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'system/warehouseManage',
        component: () => import('@/views/system-manage/warehouseManage'),
        name: 'warehouseManage',
        fullPath: '/system-manage/warehouseManage',
        meta: { title: '仓库管理', icon: 'el-icon-s-platform', noCache: true }
      },
      {
        path: 'jurisdiction/jurisdictionIndex/:idx',
        component: () => import('@/views/jurisdiction/jurisdictionIndex'),
        name: 'jurisdictionIndex',
        fullPath: '/jurisdiction/jurisdictionIndex',
        meta: { title: '权限设置', icon: 'el-icon-medal', contractType: 2, noCache: true }
      },
      {
        path: 'contacts/contactsIndex',
        fullPath: '/contacts/contactsIndex',
        component: () => import('@/views/contacts/contactsIndex'),
        name: 'contactsIndex',
        meta: { title: '员工管理', icon: 'el-icon-s-platform', noCache: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    all: true,
    component: () => import('@/views/login/index')
  },
  // 企业入驻
  {
    path: '/settlein/index',
    name: 'settleIn',
    component: () => import('@/views/settlein/index'),
    all: true
  },
  // 找回账号
  {
    path: '/RetrieveNumber/index',
    name: 'RetrieveNumber',
    component: () => import('@/views/settlein/RetrieveNumber'),
    all: true
  },
  // 找回账号 下一步
  {
    path: '/NumberBack/index',
    name: 'NumberBack',
    component: () => import('@/views/settlein/NumberBack'),
    all: true
  },
  // 找回密码FindPassword
  {
    path: '/FindPassword/index',
    name: 'FindPassword',
    component: () => import('@/views/settlein/FindPassword'),
    all: true
  },
  // 平台服务协议
  {
    path: '/doc',
    name: 'doc',
    component: () => import('@/views/settlein/doc'),
    all: true
  },
  // 帮助中心
  {
    path: '/helpCenter',
    name: 'helpCenter',
    component: () => import('@/views/helpcenter/index'),
    all: true
  }
  // {
  //   path: '/car',
  //   name: 'Car',
  //   meta: {
  //     title: '购物车',
  //     icon: 'el-icon-s-order'
  //   },
  //   components: () => import('@/views/Index/index'),
  //   children: [
  //     {
  //       path: 'car',
  //       component: () => import('@/views/car/index'),
  //       name: 'Car',
  //       meta: { title: '我的购物车', icon: 'el-icon-s-platform' }
  //     },
  //     {
  //       path: 'zhi',
  //       component: () => import('@/views/image/index'),
  //       name: 'Zhi',
  //       meta: { title: '营业执照', icon: 'el-icon-s-platform' }
  //     }
  //   ]
  // }
  // { path: '/car', name: 'Car', component: () => import('@/views/car/index') } // 购物车
  // { path: '/confirmOrder', name: 'ConfirmOrder', component: () => import('@/views/ConfirmOrder/index') }, // 确认订单
  // { path: '/orderEnd', name: 'OrderEnd', component: () => import('@/views/orderEnd/index') }, // 提交订单成功页
  // { path: '/image', name: 'Images', component: () => import('@/views/image/index') } // 提交订单成功页
]

export default routes
