import service from './index.js'
export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, { params: params }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

export function post(url, data, header) {
  return new Promise((resolve, reject) => {
    service.post(url, data, header).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * 上传文件特定请求接口
 * @data 请求的数据
 * @callback1 上传进度函数
 * @callback2 上传成功或失败的函数
*/

export function upLoadFile(data, callback1, callback2) {
  return new Promise((resolve, reject) => {
    service({
      url: '/OtherInStockUploadAccessory',
      method: 'post',
      data,
      onUploadProgress: function(progressEvent) { // 原生获取上传进度的事件
        console.log('progressEvent', progressEvent)
        if (progressEvent.lengthComputable) {
          // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
          // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
          resolve(callback1(progressEvent))
        }
      }
    }).then((res) => {
      resolve(callback2(res))
    }).catch(err => {
      console.log(err)
      reject('cuowu ', err)
    })
  })
}
