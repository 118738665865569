<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang='scss'>
@import './style/index.scss';
@import './style/main.scss';
@import './style/iconfont.scss';
</style>
