<template>
  <div class="setoldpwd" style="z-index:9999">
    <el-dialog title="" :visible.sync="setoldpwd" :show-close="false" width="30%" :append-to-body="true" :close-on-click-modal="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <p class="p-left-20 weight">修改密码</p>
        <p class="p-right-10 weight f16 cursor" style="color:#000" @click="() => setoldpwd = false">x</p>
      </div>
      <div>
        <el-form ref="form" :model="form" :rules="rules1" label-width="150px" class="demo-ruleForm">
          <el-form-item label="你的操作密码：" prop="oldpwd">
            <div class="flex ">
              <el-input v-model="form.oldpwd" size="mini" style="width:70%" />
              <span class="theme-color f12 p-left-20 cursor" @click="$router.push({name:'FindPassword'})">忘记密码？</span>
            </div>

          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
          </el-form-item>
          <el-form-item label="新操作密码：" prop="newpwd">
            <el-input v-model="form.newpwd" size="mini" style="width:70%" />
          </el-form-item>
          <el-form-item label="重复新操作密码：" prop="newpwd2">
            <el-input v-model="form.newpwd2" size="mini" style="width:70%" />
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
          </el-form-item>
        </el-form>
        <div class="submit-set flex a-center j-center">
          <el-button type="info" round size="mini" @click="() => setoldpwd = false">取消</el-button>
          <el-button :loading="isWait" type="primary" round size="mini" @click="submit('form')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'Setoldpwd',
  data() {
    return {
      setoldpwd: false,
      form: {
        oldpwd: '',
        newpwd: '',
        newpwd2: ''
      },
      isWait: false,
      rules1: {
        oldpwd: [
          { required: true, message: '请输入操作密码', trigger: 'blur' }
        ],
        newpwd: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        newpwd2: [
          {
            required: true,
            validator: async(rule, value, callback) => {
              if (!value) {
                callback(new Error('请再次输入新密码'))
              }
              if (value && value !== this.form.newpwd) {
                callback(new Error('新密码两次输入不一致'))
              }
              callback()
            },
            trigger: 'blur'
          }

        ]
      }
    }
  },
  methods: {
    openSetoldpwd() {
      this.setoldpwd = true
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.isWait = true
        this.$api.UpdateUserPwd(this.form).then(res => {
          if (res.RetCode !== '0') {
            this.isWait = false
            return this.$message.error(res.RetMsg)
          }
          this.isWait = false
          this.setoldpwd = false
          this.$message.success('密码重置成功')
        }).catch(err => {
          this.isWait = false
          return this.$message.error(err)
        })
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.submit-set {
    width: 100%;
}
</style>
