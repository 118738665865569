import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

// 自动注册模块
// const modules = {}
// const localstorage = []
const modules = {}
const modulesFiles = require.context('./modules', true, /\.js$/)
modulesFiles.keys().forEach(modulePath => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.js$/, '$1')
  const moduleContent = modulesFiles(modulePath).default
  modules[moduleName] = moduleContent
})
// console.log('modules', modules)
export default new Vuex.Store({
  modules
//   plugins: [MinVuexCache({ localStorage })]
})
