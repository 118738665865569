// 安装插件
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './router'
// import { Message } from 'element-ui'
import store from '../store/index'
// 安装路由
Vue.use(VueRouter)
// 创建路由对象并配置路由规则
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  store.dispatch('index/setFullPath', to.fullPath)
  if (to.meta.noCache) {
    // fullPath
    const arr = store.state.index.tags.filter(item => item.fullPath === to.fullPath)
    if (arr.length <= 0) {
      store.state.index.tags.push(to)
      // store.state.index.tags = Array.from(new Set(store.state.index.tags))
      store.dispatch('index/setTags', store.state.index.tags)
    }
    console.log(to)
    if (to.params.isCache === 6) {
      const fromPathIndex = store.state.index.tags.findIndex(item => item.fullPath === from.fullPath)
      store.state.index.tags.splice(fromPathIndex, 1)
      store.dispatch('index/setTags', store.state.index.tags)
      const newArr = store.state.index.cachedViews.filter(item => item !== to.name)
      store.dispatch('index/setCachedViews', newArr)
    }
    if (to.params.isCache !== 6) {
      const newArr = store.state.index.cachedViews.filter(item => item === to.name)
      if (newArr.length <= 0) {
        store.state.index.cachedViews.push(to.name)
        store.dispatch('index/setCachedViews', store.state.index.cachedViews)
      }
    }
  }
  if (to.matched.length === 0) { // 如果未匹配到路由
    next('/order/orderMananger/orderList') // 如果上级也未匹配到路由则跳转主页面，如果上级能匹配到则转上级路由
    return
  }
  if (to.name === 'Login') { // 如果是登录页，则跳过验证
    next() // 必不可少
    return // 以下的代码不执行
  }
  if (from.name === 'Login' || from.name === 'settleIn' || from.name === 'RetrieveNumber' || from.name === 'doc') { // 如果是登录页，则跳过验证
    next() // 必不可少
    return // 以下的代码不执行
  }
  var reg = new RegExp('(^| )' + 'token' + '=([^;]*)(;|$)')
  const arr = document.cookie.match(reg)
  const _token = arr ? arr[2] : ''
  if (!_token && to.fullPath !== '/settlein/index') {
    // router.push({ name: 'Login' })
    // 本地测试时 需要暂时注释掉以下跳转代码，上线测试环境时要打开
    window.location.href = Vue.prototype.$Url
  }
  // 判断是否有权限
  // const str_userMeaus = localStorage.getItem('userMeaus')
  // let userMeaus = null
  // if (str_userMeaus && str_userMeaus.length > 0) {
  //   userMeaus = JSON.parse(str_userMeaus)
  // }
  // let isgoing = false
  // userMeaus.forEach(ele => {
  //   if (to.meta.title === ele.DisplayName) {
  //     isgoing = true
  //     return false
  //   }
  // })
  // if (!isgoing) {
  //   Message({ type: 'error', message: '当前账户没有权限进入系统！！' })
  //   router.push({ name: 'Login' })
  // }
  store.dispatch('index/setallpageLoading', true)
  next()
})

// 解决重复路由的控制台报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
