import { get, post } from './request'

export default {
  // 登录
  login: data => post('/Login', data),
  // 保存企业信息
  SaveFirstCampCompanyInfo: data => post('/SaveFirstCampCompanyInfo', data),
  // 查询公司首营列表
  FirstCampCompanyList: data => post('/FirstCampCompanyList', data),
  // 获取客商公司详情
  GetFirstCampCompanyInfo: data => get('/GetFirstCampCompanyInfo', data),
  // 删除首营某项
  DeleteFirstCampCompany: data => post('/DeleteFirstCampCompany', data),
  // 查询已上传的企业证照列表
  GetFirstCampCompanyCertificateList: data => get('/GetFirstCampCompanyCertificateList', data),
  //  获取类型
  GetCertificateType: data => get('/GetCertificateType', data),
  // 保存首营执照
  SaveFirstCampCompanyCertificate: data => post('/SaveFirstCampCompanyCertificate', data),
  // 删除首营证照
  DeleteFirstCampCompanyCert: data => post('/DeleteFirstCampCompanyCert', data),
  // 获取首营资质详情
  GetFirstCampCertificateInfo: data => get('/GetFirstCampCertificateInfo', data),
  // 获取下载的相应文件流
  GetFirstCampFiles: data => get('/GetFirstCampFiles', data),
  // 导出
  ExportFirstCampCompanyInfoByUserField: data => post('/ExportFirstCampCompanyInfoByUserField', data),
  // 获取证照类型
  getFileTypes: data => get('/GetCertificateType?CertificateType=2', data),
  // 申请部门  api/basedata/PermissionGroupInfo
  PermissionGroupInfo: data => get('/basedata/PermissionGroupInfo', data),
  // 申请人 api/basedata/UserInfo?
  GetApplyUserInfo: data => get('/basedata/UserInfo', data),
  // api/DownloadFirstCampZip
  DownloadFirstCampZip: data => post('/DownloadFirstCampZip', data),
  // api/GetSellOrCustomCompanyList  客商列表
  GetSellOrCustomCompanyList: data => post('/GetSellOrCustomCompanyList', data),
  // ExportSellOrCustomCompanyListByUserField 导出客商列表
  ExportSellOrCustomCompanyListByUserField: data => post('/ExportSellOrCustomCompanyListByUserField', data),
  // api/GetCompanySupplierInfo?IDX={IDX}  客商详情
  GetCompanySupplierInfo: data => get('/GetCompanySupplierInfo', data),
  // api/GetCompanySupplierCertList?SupplierID={SupplierID} 客商已上传资质列表
  GetCompanySupplierCertList: data => get('/GetCompanySupplierCertList', data),
  // 禁用客商  api/DisableCompanySupplier?IDX={IDX}
  DisableCompanySupplier: data => post('/DisableCompanySupplier', data),
  // 保存客商 api/SaveCompanySupplier
  SaveCompanySupplier: data => post('/SaveCompanySupplier', data),
  // 保存客商证照 api/SaveCompanyCertificate
  SaveCompanyCertificate: data => post('/SaveCompanyCertificate', data),
  // 审批  保存节点 api/SaveApprovalProcessNode
  SaveApprovalProcessNode: data => post('/SaveApprovalProcessNode', data),
  // 获取节点列表 api/GetApprovalProcessList
  GetApprovalProcessList: data => get('/GetApprovalProcessList', data),
  //  删除节点  api/DeleteApprovalProcessNode
  DeleteApprovalProcessNode: data => post('/DeleteApprovalProcessNode', data),
  // api/SaveApprovalProcessFormJson  保存大项表单数据
  SaveApprovalProcessFormJson: data => post('/SaveApprovalProcessFormJson', data),
  // 查询 api/GetFormJsonByType?IDX={IDX}&Type={Type}
  GetFormJsonByType: data => get('/GetFormJsonByType', data),
  // 查询节点详情 api/GetApprovalNodeInfo?nodeID={nodeID}
  GetApprovalNodeInfo: data => get('/GetApprovalNodeInfo', data),
  //  发起首营  api/LaunchApproval
  LaunchApproval: data => post('/LaunchApproval', data),
  // 获取我的审批列表 api/GetMyApprovalList
  GetMyApprovalList: data => post('/GetMyApprovalList', data),
  // 禁用审批  api/DisableApproval
  DisableApproval: data => post('/DisableApproval', data),
  // 通过审核 api/AgreeApproval
  AgreeApproval: data => post('/AgreeApproval', data),
  // api/RejectApproval 驳回
  RejectApproval: data => post('/RejectApproval', data),
  // 审批日志 api/GetApprovalRecordList?ApprovalRecordID={ApprovalRecordID}
  GetApprovalRecordList: data => get('/GetApprovalRecordList', data),
  // api/SaveApprovalLeaveMessage 保存留言
  SaveApprovalLeaveMessage: data => post('/SaveApprovalLeaveMessage', data),
  // api/GetApprovalLeaveMessageList?RecordID={RecordID} 留言记录
  GetApprovalLeaveMessageList: data => get('/GetApprovalLeaveMessageList', data),
  // api/GetApprovalRecordInfo?RecordID={RecordID}
  GetApprovalRecordInfo: data => get('/GetApprovalRecordInfo', data),
  // api/GetApprovalUserList?RecordID={RecordID} @人列表
  GetApprovalUserList: data => get('/GetApprovalUserList', data),
  // api/GetApprovalRecordEditList?RecordID={RecordID} 查询修改日志
  GetApprovalRecordEditList: data => get('/GetApprovalRecordEditList', data),
  // api/DownMyApprovalList 导出我的审批列表
  DownMyApprovalList: data => post('/DownMyApprovalList', data),
  // api/SaveApprovalContent 保存修改数据
  SaveApprovalContent: data => post('/SaveApprovalContent', data),
  // api/DownApprovalRecordList 审批日志导出
  DownApprovalRecordList: data => post('/DownApprovalRecordList', data),
  // 库存列表QueryStockMaterialList
  QueryStockMaterialList: data => post('/QueryStockMaterialList', data),
  // api/ExportStockList 导出库存列表通过批号
  ExportStockList: data => post('/ExportStockList', data),
  // api/QueryStockDeliveryList 商品出库列表  MateriaklExportWarehouselist
  QueryStockDeliveryList: data => post('/MateriaklExportWarehouselist', data),
  // 确认发货 CreateStockOut
  CreateStockOut: data => get('/CreateStockOut', data),
  // 关闭anniu
  StockOutCloese: data => post('/StockOutCloese', data),
  // MateriaklExportWarehouseDetails 详情
  MateriaklExportWarehouseDetails: data => get('/MateriaklExportWarehouseDetails', data),
  // api/QueryDeliveryDetailsInfoByDeliveryId?DeliveryId={DeliveryId} 出库详情
  QueryDeliveryDetailsInfoByDeliveryId: data => get('/QueryDeliveryDetailsInfoByDeliveryId', data),
  // api/StockManager/QueryWarehousingList
  QueryWarehousingList: data => post('/StockManager/QueryWarehousingList', data),
  // 检验出库 ValidStockOut
  ValidStockOut: data => post('/ValidStockOut', data),
  // 获取物流信息 ExpressCompanyInfoLst
  ExpressCompanyInfoLst: data => get('/ExpressCompanyInfoLst', data),
  // 保存修改物流接口
  SaveStockOutExpress: data => post('/SaveStockOutExpress', data),
  // api/EditOrderDeliveryStatus 确认发货
  EditOrderDeliveryStatus: data => post('/EditOrderDeliveryStatus', data),
  // POST api/EditOrderStockOut  更新交货单-出库发货
  EditOrderStockOut: data => post('/EditOrderStockOut', data),
  // POST api/QueryStockDeliveryOutStockInfo   销售交货单详情
  QueryStockDeliveryOutStockInfo: data => post('/QueryStockDeliveryOutStockInfo', data),
  // POST api/EditDeliveryExpress 填写物流信息
  EditDeliveryExpress: data => post('/EditDeliveryExpress', data),
  // api/QueryCompanyAddress?CompanyID={CompanyID} 查询公司地址
  QueryCompanyAddress: data => get('/QueryCompanyAddress', data),
  // api/Company/GetCompanyNameQueryListJson 供应商、客户名称
  GetCompanyNameQueryListJson: data => post('/Company/GetCompanyNameQueryListJson', data),
  // api/QueryMaterials 获取物料列表
  QueryMaterials: data => post('/QueryMaterials', data),
  // 其他出库api/StockOutDeliveryByArtificial
  StockOutDeliveryByArtificial: data => post('/StockOutDeliveryByArtificial', data),
  // T api/StockOutLogByMaterial  出库记录-通过物料
  StockOutLogByMaterial: data => post('/StockOutLogByMaterial', data),
  // POST api/StockOutLogByMaterialExport 导出出库记录-通过物料
  StockOutLogByMaterialExport: data => post('/StockOutLogByMaterialExport', data),
  // POST api/StockOutLogByStockOutOrder   出库记录-通过出库单
  StockOutLogByStockOutOrder: data => post('/StockOutLogByStockOutOrder', data),
  // 入库 /api/QueryOrderASNInfoDetailList 按物料查询
  QueryOrderASNInfoDetailList: data => post('/QueryOrderASNInfoDetailList', data),
  // 订单 api/QueryOrderASNInfoListWithOrder
  QueryOrderASNInfoListWithOrder: data => post('/QueryOrderASNInfoListWithOrder', data),
  // 确认入库 /api/OrderASNReceiveLogSave
  OrderASNReceiveLogSave: data => post('/OrderASNReceiveLogSave', data),
  // 入库记录/api/QueryOrderASNReceiveLogList 入库记录-按物料查询
  QueryOrderASNReceiveLogList: data => post('/QueryOrderASNReceiveLogList', data),
  // 入库记录导出 物料/api/ExportQueryOrderASNInfoDetail
  ExportQueryOrderASNInfoDetail: data => post('/ExportQueryOrderASNInfoDetail', data),
  // 入库记录 导出/api/ExportOrderASNReceiveLogExcel
  ExportOrderASNReceiveLogExcel: data => post('/ExportOrderASNReceiveLogExcel', data),
  // api/OtherInStockUploadAccessory 上传附件
  OtherInStockUploadAccessory: data => post('/OtherInStockUploadAccessory', data),
  // 其他入库 确认入库 OtherInStockSave
  OtherInStockSave: data => post('/OtherInStockSave', data),
  // /api/QueryInStockOrderList 按订单查询
  QueryInStockOrderList: data => post('/QueryInStockOrderList', data),
  // 采购申请查询 /api/purchaseapply/QueryPurchaseApplyList
  QueryPurchaseApplyList: data => post('/purchaseapply/QueryPurchaseApplyList', data),
  // api/purchaseapply/QueryPurchaseApplyCount 采购申请数量
  QueryPurchaseApplyCount: data => post('/purchaseapply/QueryPurchaseApplyCount', data),
  // api/purchaseapply/QueryExpiredDateList 效期要求查询
  QueryExpiredDateList: data => get('/purchaseapply/QueryExpiredDateList', data),
  // /api/purchaseapply/QueryInStockList 入库仓库查询
  QueryInStockList: data => get('/purchaseapply/QueryInStockList', data),
  // 收货地址api/purchaseapply/QueryDeliveryAddressList
  QueryDeliveryAddressList: data => get('/purchaseapply/QueryDeliveryAddressList', data),
  // 删除地址 /api/purchaseapply/DeleteUserAddressInfo
  DeleteUserAddressInfo: data => post('/purchaseapply/DeleteUserAddressInfo', data),
  // /api/purchaseapply/QueryCollectTicketAddressList 收票
  QueryCollectTicketAddressList: data => get('/purchaseapply/QueryCollectTicketAddressList', data),
  // 客户接口 Company/GetCompanyNameQueryListJson
  GetCompanyNameQueryListJson1: data => post('Company/GetCompanyNameQueryListJson', data),
  // 通知处理人 /api/purchaseapply/QueryNoticeUserList
  QueryNoticeUserList: data => get('/purchaseapply/QueryNoticeUserList', data),
  // 科室 /api/purchaseapply/QueryDeptNameQueryListJson
  QueryDeptNameQueryListJson: data => post('/purchaseapply/QueryDeptNameQueryListJson', data),
  // /api/purchaseapply/SaveUserAddress 保存地址
  SaveUserAddress: data => post('/purchaseapply/SaveUserAddress', data),
  // 设置默认地址/api/purchaseapply/SetDefalutAddress
  SetDefalutAddress: data => post('/purchaseapply/SetDefalutAddress', data),
  // 创建采购申请 /api/purchaseapply/CreatePurchaseApply
  CreatePurchaseApply: data => post('/purchaseapply/CreatePurchaseApply', data),
  // api/purchaseapply/QueryPurchaseApplyByID 申请明细
  QueryPurchaseApplyByID: data => get('/purchaseapply/QueryPurchaseApplyByID', data),
  // 选择供应商 /api/purchaseapply/SelectContract
  SelectContract: data => post('/purchaseapply/SelectContract', data),
  // 创建采购单 /api/purchaseapply/CreatePurchaseOrder
  CreatePurchaseOrder: data => post('/purchaseapply/CreatePurchaseOrder', data),
  // 退回采购申请整单 /api/purchaseapply/BackPurchaseApply
  BackPurchaseApply: data => post('/purchaseapply/BackPurchaseApply', data),
  // /api/purchaseapply/DeletePurchaseApply 删除采购申请整单
  DeletePurchaseApply: data => post('/purchaseapply/DeletePurchaseApply', data),
  // /api/purchaseapply/DeletePurchaseApplyDetail 删除采购申请明细
  DeletePurchaseApplyDetail: data => post('/purchaseapply/DeletePurchaseApplyDetail', data),
  // /api/purchaseapply/BackPurchaseApplyDetail 退回采购申请明细
  BackPurchaseApplyDetail: data => post('/purchaseapply/BackPurchaseApplyDetail', data),
  // 采购明细导出 /purchaseapply/ExportOrderPurchaseInfoExcel
  ExportOrderPurchaseInfoExcel: data => post('/purchaseapply/ExportOrderPurchaseInfoExcel', data),
  // /api/purchaseapply/QueryOrderPurchaseOperrationInfoList
  QueryOrderPurchaseOperrationInfoList: data => post('/purchaseapply/QueryOrderPurchaseOperrationInfoList', data),
  // api/ChecCertificate 证照告警
  ChecCertificate: data => post('/ChecCertificate', data),
  // api/GetPurchaseOrderList 采购单列表
  GetPurchaseOrderList: data => post('/GetPurchaseOrderList', data),
  // api/GetOrderPurchaseStatistical 采购单统计
  GetOrderPurchaseStatistical: data => post('/GetOrderPurchaseStatistical', data),
  // 采购单导出  api/OrderPurchaseExport
  OrderPurchaseExport: data => post('/OrderPurchaseExport', data),
  // api/GetPurchaseOrderInfos?OrderPurchaseSapID={OrderPurchaseSapID} 详情采购单详情
  GetPurchaseOrderInfos: data => get('/GetPurchaseOrderInfos', data),
  // 预入库记录 api/GetASNReceiveOrderLog
  GetASNReceiveOrderLog: data => post('/GetASNReceiveOrderLog', data),
  // api/GetPurchareOrderOperationLog 操作记录
  GetPurchareOrderOperationLog: data => post('/GetPurchareOrderOperationLog', data),
  // api/DeleteOrderPurchaseSapDetails 修改/删除采购单数量
  DeleteOrderPurchaseSapDetails: data => post('/DeleteOrderPurchaseSapDetails', data),
  // api/UpdatePurchareOrderPrice 采购单修改价格
  UpdatePurchareOrderPrice: data => post('/UpdatePurchareOrderPrice', data),
  // /api/OrderPurchaseExportByIDX?IDX={IDX} 详情导出
  OrderPurchaseExportByIDX: data => get('/OrderPurchaseExportByIDX', data),
  // 删除订单 整单 DelePurchaseOrder_All
  DelePurchaseOrder_All: data => post('/DelePurchaseOrder_All', data),
  // api/CreateASNReceiveOrder 创建预入库单
  CreateASNReceiveOrder: data => post('/CreateASNReceiveOrder', data),
  // 导入预入库单 api/ExportPurchaesOrderInfo
  ExportPurchaesOrderInfo: data => post('/ExportPurchaesOrderInfo', data),
  // 获取采购订单信息 api/QueryPurchaseOrderInfoByID
  QueryPurchaseOrderInfoByID: data => get('/QueryPurchaseOrderInfoByID', data),
  // api/GetPurchaseNodeLogNow 获取采购单节点状态】
  GetPurchaseNodeLogNow: data => post('/GetPurchaseNodeLogNow', data),
  // 查询买方列表  api/GetBuyCompanyList?KeyWords={KeyWords}
  GetBuyCompanyList: data => post('/GetBuyCompanyList', data),
  // 查询终端列表 GET api/GetSendCompanyList?KeyWords={KeyWords}&BuyCompanyID={BuyCompanyID}
  GetSendCompanyList: data => post('/GetSendCompanyList', data),
  // 查询合约列表 GET api/GetProductContractList?BuyCompanyID={BuyCompanyID}&SendCompanyID={SendCompanyID}&KeyWords={KeyWords}
  GetProductContractList: data => post('/GetProductContractList', data),
  // 查询品牌列表 GET api/GetBrandList?BuyCompanyID={BuyCompanyID}&SendCompanyID={SendCompanyID}&ContractID={ContractID}&KeyWords={KeyWords}
  GetBrandList: data => post('/GetBrandList', data),
  // 类型GET api/GetMaterialTypeList?BuyCompanyID={BuyCompanyID}&SendCompanyID={SendCompanyID}&ContractID={ContractID}&BrandID={BrandID}&ParentID={ParentID}&KeyWords={KeyWords}
  GetMaterialTypeList: data => post('/GetMaterialTypeList', data),
  // 查询产品中心 api/GetProductList
  GetProductList: data => post('/GetProductList', data),
  // api/GetCompanyDepartmentList?CompanyID={CompanyID}
  GetCompanyDepartmentList: data => get('/GetCompanyDepartmentList', data),
  // 查询公司收货地址 或寄票地址GET api/GetUseraddressList?CompanyID={CompanyID}&AddrType={AddrType}
  GetUseraddressList: data => get('/GetUseraddressList', data),
  // MateriaklExportWarehouseDetailsByStockOutID 格局出库单号查询详情
  MateriaklExportWarehouseDetailsByStockOutID: data => get('/MateriaklExportWarehouseDetailsByStockOutID', data),
  // 重新发货 StockOutReOut
  StockOutReOut: data => get('/StockOutReOut', data),
  // 获取物流明细
  GetExpressInfoByStockOutID: data => get('/GetExpressInfoByStockOutID', data),
  // GET api/GetHistoryLotNum?CompanyID={CompanyID}&InvCodes={InvCodes
  GetHistoryLotNum: data => get('/GetHistoryLotNum', data),
  // api/CreateOrder
  CreateOrder: data => post('/CreateOrder', data),
  // 查询审批数量 api/Approval/GetApprovalListNum
  GetApprovalListNum: data => get('/GetApprovalListNum', data),
  // 采购订单修改备注api/SavePurchaseOrderRemark
  SavePurchaseOrderRemark: data => post('/SavePurchaseOrderRemark', data),
  // 催办 ApprovalUrging
  ApprovalUrging: data => post('/ApprovalUrging', data),
  // 重新发起 RelaunchApproval
  RelaunchApproval: data => post('/RelaunchApproval', data),
  // 查询留言未读数量
  GetApprovalLeaveMessageNewNum: data => get('/GetApprovalLeaveMessageNewNum', data),
  // 重置留言已读状态
  SaveApprovalLeaveMessageViewTime: data => post('/SaveApprovalLeaveMessageViewTime', data),
  // 地址列表
  GetUserAddressListByCompanyID: data => post('/GetUserAddressListByCompanyID', data),
  // 删除地址
  DeleteUseraddress: data => post('/DeleteUseraddress', data),
  // 新建地址
  SaveUserAddress_sys: data => post('/SaveUserAddress', data),
  // api/DoPassPortLogin saas登录
  DoPassPortLogin: data => post('/DoPassPortLogin', data),
  // api/GetValidateCodeImg 获取图片验证码
  GetValidateCodeImg: data => get('/GetValidateCodeImg', data),
  //  api/GetStockCodeList 仓库列表
  GetStockCodeList: data => get('/GetStockCodeList', data),
  // 保存仓库
  SaveStockInfo: data => post('/SaveStockInfo', data),
  //  api/DeleteStockCode 删除仓库
  DeleteStockCode: data => post('/DeleteStockCode', data),
  // api/QueryPurchaseConvertInvCodeList 物料转换
  QueryPurchaseConvertInvCodeList: data => post('/QueryPurchaseConvertInvCodeList', data),
  // api/SavePurchaseConvertInvCode 新建物料转换
  SavePurchaseConvertInvCode: data => post('/SavePurchaseConvertInvCode', data),
  // api/DeletePurchaseConvertInvCode 删除
  DeletePurchaseConvertInvCode: data => post('/DeletePurchaseConvertInvCode', data),
  // CheckStockCodeMaterial
  CheckStockCodeMaterial: data => get('/CheckStockCodeMaterial', data),
  // 预警列表 GetCertificateWaringList
  GetCertificateWaringList: data => post('/GetCertificateWaringList', data),
  // 获取预警数字 GetCertificateWarningNum
  GetCertificateWarningNum: data => post('/GetCertificateWarningNum', data),
  // 交货单报表 接口名：GetOrderDeliveryInfoList
  GetOrderDeliveryInfoList: data => post('/GetOrderDeliveryInfoList', data),
  // 交货单详情 GetOrderDeliveryDetailsInfoList
  GetOrderDeliveryDetailsInfoList: data => post('/GetOrderDeliveryDetailsInfoList', data),
  // 销售金额报表 GetSalesOrderPriceReport
  GetSalesOrderPriceReport: data => post('/GetSalesOrderPriceReport', data),
  // 订单 未清报表 接口名GetOrderInfoNotFinshList    --- post
  GetOrderInfoNotFinshList: data => post('/GetOrderInfoNotFinshList', data),
  // 采购物料清单 新接口 采购单列表接口：GetPurchaseInfoNotFinshList post
  GetPurchaseInfoNotFinshList: data => post('/GetPurchaseInfoNotFinshList', data),
  // 获取人员信息 GetCurrentUserInfo
  GetCurrentUserInfo: data => get('/GetCurrentUserInfo', data),
  // 获取短信验证码 SendPhoneValidataCode
  SendPhoneValidataCode: data => get('/SendPhoneValidataCode', data),
  // 修改人员信息  UpdateCurrentUserInfo
  UpdateCurrentUserInfo: data => post('/UpdateCurrentUserInfo', data),
  // UploadRegistCertificate 入驻上传接口
  UploadRegistCertificate: data => post('/UploadRegistCertificate', data),
  // 经营范围 数据源接口： GetRegisterApplyVSBusScopeList
  GetRegisterApplyVSBusScopeList: data => get('/GetRegisterApplyVSBusScopeList', data),
  // EditRegisterApplyInfo 企业入驻 提交
  EditRegisterApplyInfo: data => post('/EditRegisterApplyInfo', data),
  // ValidRegistCompany 通过 公司名称 以及信用码 查询公司 是否存在
  ValidRegistCompany: data => get('/ValidRegistCompany', data),
  // 获取当前公司信息接口   GetCurrentCompanyInfo     get请求
  GetCurrentCompanyInfo: data => get('/GetCurrentCompanyInfo', data),
  // 修改密码
  UpdateUserPwd: data => post('/UpdateUserPwd', data),
  // 修改企业信息 UpdateCurrentCompanyInfo
  UpdateCurrentCompanyInfo: data => post('/UpdateCurrentCompanyInfo', data),
  // 证照上传 ：AddCompanyCertificate
  AddCompanyCertificate: data => post('/AddCompanyCertificate', data),
  // 编辑证照EditCompanyCertificate
  EditCompanyCertificate: data => post('/EditCompanyCertificate', data),
  // AuthUserAcount 验证用户是否存在 忘记密码
  AuthUserAcount: data => post('/AuthUserAcount', data),
  // 重置密码 UpdatePwdByMoblie
  UpdatePwdByMoblie: data => post('/UpdatePwdByMoblie', data),
  // 删除证照DeleteCompanyCertificate
  DeleteCompanyCertificate: data => get('/DeleteCompanyCertificate', data),
  // 退出登录 logOut
  logOut: data => post('/logOut', data),
  // 列表字段管理设置SetUserField
  SetUserField: data => post('/SetUserField', data),
  // 校验是否已经不再提醒了 CheckUserLeadRecord
  CheckUserLeadRecord: data => get('/CheckUserLeadRecord', data),
  //  设置 不再提醒 接口名   UserLeadRecord
  UserLeadRecord: data => get('/UserLeadRecord', data),
  // ExportOrderASNReceiveLogExcelByUserField 导出商品入库记录-按物料查询(根据用户字典)
  ExportOrderASNReceiveLogExcelByUserField: data => post('/ExportOrderASNReceiveLogExcelByUserField', data),
  // api/ExportSalesOrderPriceReportByUserField  采购、销售金额报表 按物料导出(根据用户字典)
  ExportSalesOrderPriceReportByUserField: data => post('/ExportSalesOrderPriceReportByUserField', data),
  // POST api/ExportQueryStockMaterialListByUserField 导出商品库存 按批号/物料(根据用户字典)
  ExportQueryStockMaterialListByUserField: data => post('/ExportQueryStockMaterialListByUserField', data),
  // GET api/DeliveryDownloadQrcode?deliveryIDList={deliveryIDList} 下载二维码格式交货单 (deliveryIDList：分隔符|)
  DeliveryDownloadQrcode: data => get('/DeliveryDownloadQrcode', data),
  // GET api/DeliveryTipsPrint?deliveryIDList={deliveryIDList} 打印出库标签--云打印
  DeliveryTipsPrint: data => get('/DeliveryTipsPrint', data),
  // api/ExportStockOutLogByMaterialByUserField  导出出库记录-通过物料(根据用户字典)
  ExportStockOutLogByMaterialByUserField: data => post('/ExportStockOutLogByMaterialByUserField', data),
  // GetHelpCenterCatalogueList
  GetHelpCenterCatalogueList: data => get('/GetHelpCenterCatalogueList', data),
  // GetHelpContentInfo
  GetHelpContentInfo: data => get('/GetHelpContentInfo', data),
  // 订单处理 导出 api/ExportOrderAndDetailList
  ExportOrderAndDetailList: data => post('/ExportOrderAndDetailList', data),
  // POST api/ExprotGetOrderDeliveryInfoList   交货单报表-导出
  ExprotGetOrderDeliveryInfoList: data => post('/ExprotGetOrderDeliveryInfoList', data),
  // 意见反馈api/SaveFeedBack
  SaveFeedBack: data => post('/SaveFeedBack', data),
  // api/purchaseapply/ExportQueryPurchaseApplyByID 采购申请-导出
  ExportQueryPurchaseApplyByID: data => post('/purchaseapply/ExportQueryPurchaseApplyByID', data),
  // api/ExportGetPurchaseOrderList 采购单收货-导出
  ExportGetPurchaseOrderList: data => post('/ExportGetPurchaseOrderList', data),
  // QueryOrderASNReceiveLogReportList 预收货记录
  QueryOrderASNReceiveLogReportList: data => post('/QueryOrderASNReceiveLogReportList', data),
  //  ExportContractDetailsAndCInfo 销售、采购合约明细
  ExportContractDetailsAndCInfo: data => post('/ExportContractDetailsAndCInfo', data),
  // ExportGetOrderInfoNotFinshList
  ExportGetOrderInfoNotFinshList: data => post('/ExportGetOrderInfoNotFinshList', data),
  // 导出  采购物料清单  ExportPurchaseInfoNotFinsh
  ExportPurchaseInfoNotFinsh: data => post('/ExportPurchaseInfoNotFinsh', data),
  // GetOrderDeliveryPrintInfo
  GetOrderDeliveryPrintInfo: data => post('/GetOrderDeliveryPrintInfo', data),
  // CompanyDeliveryReportCustomSave
  CompanyDeliveryReportCustomSave: data => post('/CompanyDeliveryReportCustomSave', data),
  // ExportQueryOrderASNReceiveLogReportList    预收货记录-按物料导出
  ExportQueryOrderASNReceiveLogReportList: data => post('/ExportQueryOrderASNReceiveLogReportList  ', data),
  //  GetStockWarningList库存设置 列表查询 接口名称： GetStockWarningList
  GetStockWarningList: data => post('/GetStockWarningList', data),
  // GetStockWarningListByType 库存不足 库存积压 等 那4个类型的列表接口
  GetStockWarningListByType: data => post('/GetStockWarningListByType', data),
  //  EditMaterialStockWarning 预警
  EditMaterialStockWarning: data => post('/EditMaterialStockWarning', data),
  // 进入买家
  PunchBuy: data => get('/PunchBuy', data),
  // 按调拨单查询接口 GetInventoryTransferInfoList   post 请求
  GetInventoryTransferInfoList: data => post('/GetInventoryTransferInfoList', data),
  // 调拨 保存/发起 接口 SaveInventoryTransferInfo  post 请求
  SaveInventoryTransferInfo: data => post('/SaveInventoryTransferInfo', data),
  // 获取可添加的物料接口  GetSellCompanyMateialStockList post
  GetSellCompanyMateialStockList: data => post('/GetSellCompanyMateialStockList', data),
  // 查询 库存调拨明细 接口：GetInventoryTransferInfo  get方式  入参  IDX
  GetInventoryTransferInfo: data => get('/GetInventoryTransferInfo', data),
  // 导出库存调拨 接口：InventoryTransfer_Export
  InventoryTransfer_Export: data => post('/InventoryTransfer_Export', data),
  // InventoryTransfer_Import 库存调拨导入
  InventoryTransfer_Import: data => post('/InventoryTransfer_Import', data),
  // InventoryTransferInfo_Close 关闭调拨单
  InventoryTransferInfo_Close: data => get('/InventoryTransferInfo_Close', data),
  // 获取头部数量
  GetNums: data => get('/GetNums', data),
  // 盘点修正列表GetStockCorrectInfoList
  GetStockCorrectInfoList: data => post('/GetStockCorrectInfoList', data),
  // GetStockCorrectInfoByIDX 盘点详情
  GetStockCorrectInfoByIDX: data => get('/GetStockCorrectInfoByIDX', data),
  // GetMateialStockListByStockCode 添加产品 盘点
  GetMateialStockListByStockCode: data => post('/GetMateialStockListByStockCode', data),
  // 获取明细GetStockSellByStockInv
  GetStockSellByStockInv: data => post('/GetStockSellByStockInv', data),
  // SaveStockCorrectInfo 保存新增盘点
  SaveStockCorrectInfo: data => post('/SaveStockCorrectInfo', data),
  // StockCorrecting 盘点修正接口
  StockCorrecting: data => post('/StockCorrecting', data),
  // UpdateStockCorrectStatus 完成盘点
  UpdateStockCorrectStatus: data => post('/UpdateStockCorrectStatus', data),
  // 盘点明细导出接口：CorrectDetails_Export  post请求
  CorrectDetails_Export: data => post('/CorrectDetails_Export', data),
  // CorrectDetails_ImportUpload
  CorrectDetails_ImportUpload: data => post('/CorrectDetails_ImportUpload ', data),
  // 盘点单列表 导出接口：StockCorrect_Export
  StockCorrect_Export: data => post('/StockCorrect_Export', data),
  // 修改库存信息 接口： EditMaterialStockInfo post
  EditMaterialStockInfo: data => post('/EditMaterialStockInfo', data),
  // 查询库存修改日志接口 ： GetUpdateMaterialStockLogListByStockID  get  入参： StockSellID
  GetUpdateMaterialStockLogListByStockID: data => post('/GetUpdateMaterialStockLogList', data),
  // 创建采购申请导入 CreatePurchase_Import
  CreatePurchase_Import: data => post('/CreatePurchase_Import', data),
  // GetInvoiceList 查询发票
  GetInvoiceList: data => get('/GetInvoiceList', data),
  // SaveDeliveryInvoiceInfo 保存发票
  SaveDeliveryInvoiceInfo: data => post('/SaveDeliveryInvoiceInfo', data),
  // 发票列表 GetOrderInvoiceInfoList
  GetOrderInvoiceInfoList: data => post('/GetOrderInvoiceInfoList', data),
  // 删除发票接口： DeleteOrderInvoiceInfo  get  入参 ： idxs:"1,2" // 多个用逗号分隔
  DeleteOrderInvoiceInfo: data => get('/DeleteOrderInvoiceInfo', data),
  // 获取发票详情接口：GetOrderInvoiceInfoDetails  get方式 入参：IDX：12  //发票主键
  GetOrderInvoiceInfoDetails: data => get('/GetOrderInvoiceInfoDetails', data),
  // 同步发票关联
  EditOrderInvoiceSyncFlag: data => post('/EditOrderInvoiceSyncFlag', data)
}
